import styled, { css } from 'styled-components';
import config from '../config';

const Carrousel = styled.section`
  padding: 4.8rem 0;
  background-color: ${config.palette.darkBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;

    & > article {
      width: 100%;
    }
  }
`;

const TextCarrousel = styled.article`
  width: 60%;

  h2,
  em,
  p {
    color: ${config.palette.white};
  }

  h2 {
    margin-bottom: 3.2rem;
  }

  p {
    margin-top: 1.6rem;
  }

  & > div {
    padding: 1.6rem 0.8rem;
    background-image: url(assets/quote/quotation-marks.png);
    background-repeat: no-repeat;
  }
`;

const Image = styled.article`
  width: calc(40% - 12rem);

  img {
    max-width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

const Pagination = styled.section`
  width: 100%;
  display: flex;
  padding: 2rem 0;
  margin-top: 2.4rem;
`;

const Swipeable = styled.div`
  display: flex;

  svg {
    cursor: pointer;
  }

  svg:first-child {
    margin-right: 2.4rem;
  }
  svg {
    cursor: pointer;

    path {
      fill: ${config.palette.white};
    }
  }
`;

const PaginationItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-right: 2.8rem;
  }
`;

const CompanyIndex = styled('article')<{ totalItems: number }>`
  width: ${props => 100 / (props.totalItems / 3)}%;
  height: 0.2rem;
  margin-right: 0.4rem;
  background-color: ${config.palette.whiteTransparent('0.4')};

  &.active {
    background-color: ${config.palette.white};
  }
`;

const LayoutCarrousel = css`
  background-color: ${config.palette.darkBlue};
`;

export {
  Carrousel,
  TextCarrousel,
  Image,
  Pagination,
  Swipeable,
  PaginationItems,
  CompanyIndex,
  LayoutCarrousel
};
