import { updateDataLayer } from '../../lib/datalayer';

function emitLearningEvent(course: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click text',
    eventLabel: `student learnings::${course}`
  });
}

export { emitLearningEvent };
