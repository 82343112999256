import React from 'react';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import { windowDefined } from '../../lib/utils';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import StatsList from '../../components/StatsList';
import Grid from '../../components/Grid';
import WhatOurGraduatesLearn from '../../components/WhatOurGraduatesLearn';
import HowToHireFromUs from '../../components/HowToHireFromUs';
import Partner from '../../components/Partner';
import Footer from '../../components/Footer';
import WhyIronhackItem from '../../components/WhyIronhackItem';
import {
  HireDigitalTalentTitleButton,
  BackgroundMobileTransparent,
  HireDigitalTalentStats,
} from './styles';
import Layout from '../../components/atoms/layout';
import IframeContainer from '../../components/IframeContainer';
import HireDigitalTalentProps from '../../interface/template';
import CompanyTestimonial from '../../components/CompanyTestimonial';
import InternalLinks from '../../containers/InternalLinks';
import { Family, Illuminati, Skills, Space } from '../../icons';
import * as gaEvents from './ga_events';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const typeformContainerId = 'typeform-container';

const HireDigitalTalent: React.SFC<any> = (props: HireDigitalTalentProps) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('hire-tech-talent:hireTechTalent.mainHeadline.subtitle')}
          title={t('hire-tech-talent:hireTechTalent.mainHeadline.title')}
        >
          <BackgroundMobileTransparent />
          <HireDigitalTalentTitleButton
            onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
              e.preventDefault();
              gaEvents.emitCtaEvent();
              const typeformWrapper =
                document.getElementById(typeformContainerId);
              if (windowDefined() && typeformWrapper) {
                window.scroll({
                  top: typeformWrapper.offsetTop,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {t('hire-tech-talent:hireTechTalent.mainHeadline.cta')}
          </HireDigitalTalentTitleButton>
        </PageTitle>
      </Header>
      <Layout extend={HireDigitalTalentStats}>
        <StatsList context="enterprise" />
      </Layout>
      <Grid title={t('hire-tech-talent:hireTechTalent.whyHireFromUs.title')}>
        <WhyIronhackItem
          image={Space}
          text={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.01.description'
          )}
          title={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.01.title'
          )}
        />
        <WhyIronhackItem
          image={Skills}
          text={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.02.description'
          )}
          title={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.02.title'
          )}
        />
        <WhyIronhackItem
          image={Family}
          text={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.03.description'
          )}
          title={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.03.title'
          )}
        />
        <WhyIronhackItem
          image={Illuminati}
          text={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.04.description'
          )}
          title={t(
            'hire-tech-talent:hireTechTalent.whyHireFromUs.description.04.title'
          )}
        />
      </Grid>
      <WhatOurGraduatesLearn />
      <CompanyTestimonial />
      <HowToHireFromUs />
      <Partner
        subtitle={t(
          'hire-tech-talent:hireTechTalent.hiringPartners.description'
        )}
        title={t('hire-tech-talent:hireTechTalent.hiringPartners.title')}
        viewButton={false}
      />
      <IframeContainer
        id={typeformContainerId}
        src="https://ironhack.typeform.com/to/U5hVO0"
      />
      <InternalLinks />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(hire-tech-talent)|(company-testimonials)|(seo)|(breadcrumbs)|(banner)|(navigation)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(HireDigitalTalent);
