import React, { useState } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { Title2, Courses, Cards, Card } from './styles';
import Layout from '../atoms/layout';
import { Text1, Text3 } from '../atoms/body';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as gaEvents from './ga_events';

const WhatOurGraduatesLearn: React.SFC<any> = (props: { t: TFunction }) => {
  const { t } = props;
  const [courseSelect, setCourseSelect] = useState('web');

  const graduates = [
    {
      course: 'web',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.01.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.01.title'
      )
    },
    {
      course: 'web',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.02.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.02.title'
      )
    },
    {
      course: 'web',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.03.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.03.title'
      )
    },
    {
      course: 'web',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.04.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.web.04.title'
      )
    },
    {
      course: 'uxui',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.01.description'
      ),
      title: t('hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.01.title')
    },
    {
      course: 'uxui',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.02.description'
      ),
      title: t('hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.02.title')
    },
    {
      course: 'uxui',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.03.description'
      ),
      title: t('hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.03.title')
    },
    {
      course: 'uxui',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.04.description'
      ),
      title: t('hire-tech-talent:hireTechTalent.whatGraduatesLearn.ux.04.title')
    },
    {
      course: 'data',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.01.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.01.title'
      )
    },
    {
      course: 'data',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.02.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.02.title'
      )
    },
    {
      course: 'data',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.03.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.03.title'
      )
    },
    {
      course: 'data',
      text: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.04.description'
      ),
      title: t(
        'hire-tech-talent:hireTechTalent.whatGraduatesLearn.data.04.title'
      )
    }
  ];

  const courseCard = () => {
    return graduates
      .filter(graduate => graduate.course === courseSelect)
      .map(graduate => (
        <Card key={graduate.title}>
          <Text1 as="h4">{graduate.title}</Text1>
          <Text3 as="p">{graduate.text}</Text3>
        </Card>
      ));
  };

  return (
    <>
      <Layout>
        <Title2
          as="h2"
          dangerouslySetInnerHTML={{
            __html: t(
              'hire-tech-talent:hireTechTalent.whatGraduatesLearn.title'
            ).replace(/em/g, 'span')
          }}
        />
        <Courses>
          <h3
            className={classNames({
              active: courseSelect === 'web'
            })}
            onClick={(): void => {
              gaEvents.emitLearningEvent('wd');
              setCourseSelect('web');
            }}
          >
            Web
          </h3>
          <h3
            className={classNames({
              active: courseSelect === 'uxui'
            })}
            onClick={(): void => {
              gaEvents.emitLearningEvent('ux');
              setCourseSelect('uxui');
            }}
          >
            UX/UI
          </h3>
          <h3
            className={classNames({
              active: courseSelect === 'data'
            })}
            onClick={(): void => {
              gaEvents.emitLearningEvent('da');
              setCourseSelect('data');
            }}
          >
            Data
          </h3>
        </Courses>
        <Cards>{courseCard()}</Cards>
      </Layout>
    </>
  );
};

export default withTranslation()(WhatOurGraduatesLearn);
