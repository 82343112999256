import styled, { css } from 'styled-components';
import config from '../config';

const Contain = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    max-width: 100%;
  }

  article:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
      width: calc(50% - 6.4rem);
    }
  }

  article {
    width: calc(50% - 1.6rem);
    margin-top: 3.2rem;
    background-color: ${config.palette.orangeTransparent('0.1')};
    border-radius: 0.6rem;
    align-items: center;
    padding: 4.8rem;
    flex-wrap: wrap;
  }

  p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-top: 1.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    article:first-child {
      div {
        width: 100%;
        margin-bottom: 3.2rem;
      }
    }

    article {
      background-color: initial;
      padding: 0;
      width: 100%;
      margin-top: 2.8rem;
    }
  }
`;

const LayoutHowToHireFromUs = css`
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;

  p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.4')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background-color: ${config.palette.orangeTransparent('0.1')};
  }
`;

export { Contain, LayoutHowToHireFromUs };
