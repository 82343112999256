import React from 'react';
import Layout from './styles';
interface Props {
  id: string;
  src: string;
}

function IframeContainer(props: Props): JSX.Element {
  const { id, src } = props;

  return (
    <Layout id={id}>
      <iframe loading="lazy" src={src} />
    </Layout>
  );
}

export default IframeContainer;
