import styled, { css } from 'styled-components';
import config from '../../components/config';

const HireDigitalTalentTitleButton = styled.a`
  background: ${config.palette.lightBlue};
  border-radius: ${config.borderRadius};
  color: ${config.palette.white};
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  line-height: 160%;
  margin-top: 3.2rem;
  padding: 2.4rem 9rem;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease-in-out background;

  &:hover {
    background: ${config.palette.lightBlueHover};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    position: fixed;
    width: 90%;
    left: 2rem;
    bottom: 0.8rem;
    padding: 1.4rem 0;
    z-index: 9;
    font-size: 1.6rem;
    line-height: 125%;
  }
`;

const BackgroundMobileTransparent = styled.div`
  display: none;

  @media (max-width: ${config.resolutions.mobileL}) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 7rem;
    width: 100vw;
    background-color: ${config.palette.white};
    opacity: 0.6;
    z-index: 8;
  }
`;

const HireDigitalTalentStats = css`
  @media (max-width: ${config.resolutions.mobileL}) {
    & > section {
      padding-bottom: 4rem;
    }
  }
`;

export {
  HireDigitalTalentTitleButton,
  BackgroundMobileTransparent,
  HireDigitalTalentStats
};
