import styled from 'styled-components';
import config from '../config';
import Layout from '../atoms/layout';

export default styled(Layout)`
  height: 75vh;

  iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-left: 0;
    padding-right: 0;
  }
`;
