import { updateDataLayer } from '../../lib/datalayer';

function emitArrowEvent(direction: 'left' | 'right'): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click icon',
    eventLabel: `company testimonials::navigation arrow::${direction}`
  });
}

export { emitArrowEvent };
