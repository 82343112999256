import React, { useState } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import { Text4 } from '../atoms/body';
import Queote2 from '../atoms/quotes/quote2';
import ArrowLeft from '../../../static/assets/icons/arrows/arrow-left.svg';
import ArrowRight from '../../../static/assets/icons/arrows/arrow-right.svg';
import {
  Carrousel,
  TextCarrousel,
  Image,
  Pagination,
  Swipeable,
  PaginationItems,
  CompanyIndex,
  LayoutCarrousel
} from './styles';
import * as gaEvents from './ga_events';

interface Company {
  logoUrl: string;
  title: string;
  testimonial: string;
  authorName: string;
  authorJob: string;
}

const CompanyTestimonail: React.SFC<any> = (props: { t: TFunction }) => {
  const { t } = props;
  const [pagination, setPagination] = useState(0);

  const companiesCarrousel = Object.values<Company>(
    t('company-testimonials:companyTestimonials', { returnObjects: true })
  );

  const paginationCreator = () => {
    const items = [];
    const length = companiesCarrousel.length;
    for (let i = 0; i < length; i++) {
      items.push(
        <CompanyIndex
          totalItems={companiesCarrousel.length}
          key={uniqid()}
          className={classNames({
            active: i === pagination
          })}
        />
      );
    }
    return items;
  };

  const changeCard = (num: number) => {
    const next = pagination + num;
    if (next < 0) {
      setPagination(companiesCarrousel.length - 1);
    } else if (next >= companiesCarrousel.length) {
      setPagination(0);
    } else {
      setPagination(next);
    }
  };

  const companyCards = companiesCarrousel.map((company: Company) => (
    <>
      <Image>
        <img
          src={`/assets/why-they-hired/${company.logoUrl}`}
          alt={company.logoUrl.slice(0, -4)}
        />
      </Image>
      <TextCarrousel>
        <Title1 as="h2">{company.title}</Title1>
        <div>
          <Queote2>{company.testimonial}</Queote2>
          <Text4 as="p">{`${company.authorName} - ${company.authorJob}`}</Text4>
        </div>
        <Pagination>
          <PaginationItems>{paginationCreator()}</PaginationItems>
          <Swipeable>
            <ArrowLeft
              onClick={(): void => {
                gaEvents.emitArrowEvent('left');
                changeCard(-1);
              }}
            />
            <ArrowRight
              onClick={(): void => {
                gaEvents.emitArrowEvent('right');
                changeCard(1);
              }}
            />
          </Swipeable>
        </Pagination>
      </TextCarrousel>
    </>
  ));
  return (
    <Layout extend={LayoutCarrousel}>
      <Carrousel>{companyCards[pagination]}</Carrousel>
    </Layout>
  );
};

export default withTranslation()(CompanyTestimonail);
