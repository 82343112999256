import React from 'react';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import { Text3, Text1 } from '../atoms/body';
import { Contain, LayoutHowToHireFromUs } from './styles';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

function HowToHireFromUs(props: { t: TFunction }) {
  const { t } = props;
  return (
    <Layout extend={LayoutHowToHireFromUs}>
      <Title1 as="h2">
        {t('hire-tech-talent:hireTechTalent.howToHire.title')}
      </Title1>
      <Text3 as="p">
        {`${t('hire-tech-talent:hireTechTalent.howToHire.description.01')} ${t(
          'hire-tech-talent:hireTechTalent.howToHire.description.02'
        )}`}
      </Text3>
      <Contain>
        <article>
          <div>
            <Text1 as="h3">
              {t('hire-tech-talent:hireTechTalent.howToHire.items.01.title')}
            </Text1>
            <Text3 as="p">
              {t(
                'hire-tech-talent:hireTechTalent.howToHire.items.01.description'
              )}
            </Text3>
          </div>
          <img src="/assets/career/week.png" alt="team" />
        </article>
        <article>
          <Text1 as="h3">
            {t('hire-tech-talent:hireTechTalent.howToHire.items.02.title')}
          </Text1>
          <Text3 as="p">
            {t(
              'hire-tech-talent:hireTechTalent.howToHire.items.02.description'
            )}
          </Text3>
        </article>
        <article>
          <Text1 as="h3">
            {t('hire-tech-talent:hireTechTalent.howToHire.items.03.title')}
          </Text1>
          <Text3 as="p">
            {t(
              'hire-tech-talent:hireTechTalent.howToHire.items.03.description'
            )}
          </Text3>
        </article>
      </Contain>
    </Layout>
  );
}

export default withTranslation()(HowToHireFromUs);
