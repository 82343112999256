import { updateDataLayer } from '../../lib/datalayer';

function emitCtaEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click cta::contact',
    eventLabel: 'header::primary cta'
  });
}

export { emitCtaEvent };
