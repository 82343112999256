import styled, { css } from 'styled-components';
import config from '../config';
import { Component } from '../atoms/headers/title2/Component';

const Title2 = styled(Component)`
  margin-bottom: 3.2rem;

  span {
    color: ${config.palette.lightBlue};
  }
`;

const Courses = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;

  h3 {
    border-bottom: 0.1rem solid ${config.palette.darkBlueTransparent('0.6')};
    color: ${config.palette.darkBlueTransparent('0.6')};
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    width: 33.3333%;
  }

  h3.active {
    font-weight: 500;
    color: ${config.palette.lightBlue};
    border-bottom: 0.2rem solid ${config.palette.lightBlue};
  }
`;

const Cards = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 6.4rem;
`;

const Card = styled.article`
  width: calc(50% - 1.6rem);
  padding: 4rem;
  background-color: ${config.palette.darkBlueTransparent('0.05')};
  border-radius: 0.6rem;
  margin-top: 4.8rem;

  p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    margin-top: 2.4rem;
  }
`;

export { Title2, Courses, Cards, Card };
